import * as programActions from "@app/ngrx.store/workbook.state/workbook.actions";
import {initialState, State} from "@app/ngrx.store/workbook.state/workbook.state";
import {RESET_WORKBOOK, SET_WORKBOOK} from "@app/ngrx.store/workbook.state/workbook.actions";

export function reducer(state: any, action: any): any {
  switch (action.type) {
    case SET_WORKBOOK: {
      return {
        ...state,
        workbook: action.payLoad,
      } as State;
    };
    case RESET_WORKBOOK: {
      return initialState;
    };
    default: {
      return {
        ...state,
      }
    }
  }
}
