import {Action} from "@ngrx/store";
import {User} from "../../domain/User";

export const SET_USER = "Set User";
export const NEW_USER = "New User";
export const INIT_USER = "Init User";

export class SetUser implements Action {
  readonly type: string = SET_USER;

  constructor(public payLoad: User) {
  }
}

export class NewUser implements Action {
  readonly type: string = NEW_USER;

  constructor(public payLoad: User) {
  }
}

export class InitUser implements Action {
  readonly type: string = INIT_USER;

  constructor(public payLoad: any) {
  }
}

export type UserAction = SetUser | NewUser;
