import {concatUrl as concat} from "../helpers";

/**
 *  API keys;
 */
const API = 'api';
const AUTHENTICATION = 'authentication';
const FIRST_LOGIN = 'first-login';
const RESET_PASSWORD = 'reset-password';
const PASSWORD_POLICY = 'password-policy';
const RESET_EMAIL = 'reset-email';
const LOGOUT = 'logout';

export const API_LOGOUT = concat(API, LOGOUT);
export const API_AUTHENTICATION = concat(API, AUTHENTICATION);
export const API_AUTHENTICATION_FIRST_LOGIN = concat(API_AUTHENTICATION, FIRST_LOGIN);
export const API_AUTHENTICATION_RESET_PASSWORD = concat(API_AUTHENTICATION, RESET_PASSWORD);
export const API_AUTHENTICATION_PASSWORD_POLICY = concat(API_AUTHENTICATION, PASSWORD_POLICY);
export const API_AUTHENTICATION_RESET_EMAIL = concat(API_AUTHENTICATION, RESET_EMAIL);
