import {Action} from "@ngrx/store";
import {MlModule} from "../../domain/Module";

export const SET_MODULES = "Set Modules";

export class SetModules implements Action {
  readonly type: string = SET_MODULES;

  constructor(public payLoad: MlModule[]) {
  }
}


export type ModulesAction = SetModules;
