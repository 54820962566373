import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CdkStepperModule} from "@angular/cdk/stepper";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatNativeDateModule} from "@angular/material/core";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSelectModule} from "@angular/material/select";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {MatTabsModule} from "@angular/material/tabs";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatAutocompleteModule, MatCheckboxModule, MatChipsModule, MatDialogModule, MatMenuModule, MatRadioModule} from "@angular/material";

const modules = [
  CdkStepperModule,
  MatButtonModule,
  MatCardModule,
  MatInputModule,
  MatFormFieldModule,
  MatSelectModule,
  MatDatepickerModule,
  MatTabsModule,
  MatNativeDateModule,
  MatToolbarModule,
  MatTableModule,
  MatIconModule,
  MatSortModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatRadioModule,
  MatCheckboxModule,
  MatDialogModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatExpansionModule,
  MatMenuModule,
];

@NgModule({
  imports: [
    CommonModule,
    ...modules
  ],
  exports: [...modules]
})
export class MaterialModule {
}
