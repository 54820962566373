import * as programActions from './process.actions';
import {SET_PROCESS_STATE} from './process.actions';
import {initialState, State} from "./process.state";

export function reducer(state: any, action: programActions.ProcessActions): any {
  switch (action.type) {
    case SET_PROCESS_STATE: {
      return {
        ...state,
        ...action.payLoad
      } as State;
    };
    default: {
      return {
        ...state,
      }
    }
  }
}
