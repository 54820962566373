import {RESET_PORTFOLIO, SET_PORTFOLIO} from "./portfolio.actions";
import {initialState, State} from "./portfolio.state";


export function reducer(state: any, action: any) {
  switch (action.type) {
    case SET_PORTFOLIO: {
      return {
        ...state,
        portfolioKey: action.payLoad,
      };
    };

    case RESET_PORTFOLIO: {
      return initialState as State;
    };

    default: {
      return {
        ...state
      }
    }
  }
}
