import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ResizableModule} from 'angular-resizable-element';

import {AppComponent} from "@app/app.component";
import {AppRoutingModule} from "@app/app-routing.module";
import {CoreModule} from "@core/core.module";

import {NgxMaskModule} from "ngx-mask";
import {MaterialModule} from "@shared/modules/material/material.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,

    CoreModule,
    ResizableModule,
    NgxMaskModule.forRoot()
  ],
    exports: [
    ],
  bootstrap: [AppComponent]
})
export class AppModule {
}


