import {Action} from "@ngrx/store";

export const INIT_PROCESS_STATE = "Init Process State";
export const SET_PROCESS_STATE = "Set Process State";

export class InitProcessState implements Action {
  readonly type: string = INIT_PROCESS_STATE;
  constructor(public payLoad: any) {
  }
}

export class SetProcessState implements Action {
  readonly type: string = SET_PROCESS_STATE;
  constructor(public payLoad: {running: number, failed: number}) {
  }
}


export type ProcessActions = SetProcessState | InitProcessState;
