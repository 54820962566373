import {ActionReducerMap} from '@ngrx/store';
import * as userStore from './ngrx.store/user.state';
import * as moduleStore from './ngrx.store/module.state';
import * as modulesStore from './ngrx.store/modules.state';
import * as taskStore from './ngrx.store/task.state';
import * as bpmnStore from './ngrx.store/bpmn.state';
import * as clientStore from './ngrx.store/client.state';
import * as portfolioStore from './ngrx.store/portfolio.state';
import * as bookDateStore from './ngrx.store/bookdate.state';
import * as workbookStore from './ngrx.store/workbook.state';
import * as processStore from './ngrx.store/process.state';

export interface AppState {
  user: userStore.State,
  module: moduleStore.State
  task: taskStore.State
  bpmn: bpmnStore.State
  client: clientStore.State
  modules: modulesStore.State,
  portfolio: portfolioStore.State,
  bookDate: bookDateStore.State,
  workbook: workbookStore.State,
  processState: processStore.State
}

export const initialState: AppState = {
  user: userStore.initialState,
  module: moduleStore.initialState,
  task: taskStore.initialState,
  bpmn: bpmnStore.initialState,
  client: clientStore.initialState,
  modules: modulesStore.initialState,
  portfolio: portfolioStore.initialState,
  bookDate: bookDateStore.initialState,
  workbook: workbookStore.initialState,
  processState: processStore.initialState
}

export const ngrxReducers: ActionReducerMap<AppState> = {
  user: userStore.reducer,
  module: moduleStore.reducer,
  task: taskStore.reducer,
  bpmn: bpmnStore.reducer,
  client: clientStore.reducer,
  modules: modulesStore.reducer,
  portfolio: portfolioStore.reducer,
  bookDate: bookDateStore.reducer,
  workbook: workbookStore.reducer,
  processState: processStore.reducer
}

export const getUser = (s: AppState) => s.user;
export const getModule = (s: AppState) => s.module;
export const getModules = (s: AppState) => s.modules;
export const getTask = (s: AppState) => s.task;
export const getBpmn = (s: AppState) => s.bpmn;
export const getClient = (s: AppState) => s.client;
export const getPortfolio = (s: AppState) => s.portfolio;
export const getBookDate = (s: AppState) => s.bookDate;
export const getWorkbook = (s: AppState) => s.workbook;
export const getProccessState = (s: AppState) => s.processState;
