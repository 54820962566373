import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StyleService {

  constructor() { }

  appUrl: string

  init(url: string) {
    this.appUrl = url
  }

  getStylePrefix() {
    if(this.appUrl != null && this.appUrl.indexOf('milliman') !== -1) {
      return 'milliman'
    }
    return null
  }
}
