import * as programActions from './bpmn.actions';
import {UPDATE_STATE, } from "./bpmn.actions";
import {initialState} from "./bpmn.state";


export function reducer(state: any, action: programActions.BpmnAction) {
  switch (action.type) {
    case UPDATE_STATE: {
      const payload = action.payLoad as boolean
      return {
        ...state,
        updated: payload,
      }
    }
    default: {
      return {
        ...initialState
      }
    }
  }
}
