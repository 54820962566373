import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {IsNotAuthenticatedGuard} from "@core/guards/is-not-authenticated.guard";
import {IsAuthenticatedGuard} from "@core/guards/is-authenticated.guard";

const routes: Routes = [
  {
    path: 'login',
    canActivate: [IsNotAuthenticatedGuard],
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'first-login',
    canActivate: [IsAuthenticatedGuard],
    loadChildren: () => import('./first-login/first-login.module').then(m => m.FirstLoginModule)
  },
  {
    path: '',
    canActivate: [IsAuthenticatedGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    enableTracing: false,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
