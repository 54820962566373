import {Action} from "@ngrx/store";

export const INIT_PORTFOLIO = "Init portfolio";
export const SET_PORTFOLIO = "Set portfolio";
export const RESET_PORTFOLIO = "Reset portfolio";

export class InitPortfolio implements Action {
  readonly type: string = INIT_PORTFOLIO;
  constructor(public payLoad: Date) {
  }
}

export class SetPortfolio implements Action {
  readonly type: string = SET_PORTFOLIO;
  constructor(public payLoad: string) {
  }
}

export class ResetPortfolio implements Action {
  readonly type: string = RESET_PORTFOLIO;
  constructor() {
  }
}
export type PortfolioActions = InitPortfolio | SetPortfolio | ResetPortfolio;
