import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material";

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private snackBar: MatSnackBar) {}

  /** show success toast */
  success = (message: string): void => this.showToast(message, toastMessageClass.green);
  /** show error toast */
  error = (message: string): void => this.showToast(message, toastMessageClass.red);
  /** show info toast */
  info = (message: string): void => this.showToast(message);
  /** show warning toast */
  warning = (message: string): void => this.showToast(message, toastMessageClass.orange);


  private showToast(message, classSelector?: toastMessageClass): void {
    this.snackBar.open('', message, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: classSelector || ''
    });
  }
}

enum toastMessageClass {
  green = 'green',
  red = 'red',
  orange = 'orange',
}
