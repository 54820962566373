import {SET_TASK_TO_EXECUTE, CLEAR_TASK_TO_EXECUTE} from "./task.actions";
import {initialState} from "./task.state";
import {BpmnTask} from "@modules/fx-overlay/bpmn/domain";

export function reducer(state: any, action: any) {
  switch (action.type) {
    case SET_TASK_TO_EXECUTE: {
      const payloadAsTask = action.payLoad as BpmnTask
      return {
        ...state,
        taskToExecute: payloadAsTask,
      }
    }
    case CLEAR_TASK_TO_EXECUTE: {
      return {
        ...initialState,
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
}
