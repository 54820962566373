export class Role {

  constructor(public id: number, public roleType: RoleType, public defaultRoute: string) {

  }
};

export enum RoleType {
  ADMIN = "ADMIN",
  DATA_ADMIN = "DATA_ADMIN",
  REPORT_ADMIN = "REPORT_ADMIN",
  MODULE_ADMIN = "MODULE_ADMIN",
  DATA_MANAGER = "DATA_MANAGER",
  REPORTER = "REPORTER",
  CONFIGURATOR = "CONFIGURATOR"

};

export enum ROLES {
  ADMIN,
  DATA_ADMIN,
  REPORT_ADMIN,
  MODULE_ADMIN,
  DATA_MANAGER,
  REPORTER,
  CONFIGURATOR
}
export type TUserRoles =
  RoleType.ADMIN |
  RoleType.DATA_ADMIN |
  RoleType.REPORT_ADMIN |
  RoleType.MODULE_ADMIN |
  RoleType.DATA_MANAGER |
  RoleType.REPORTER |
  RoleType.CONFIGURATOR;
