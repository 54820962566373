import {RESET_MODULE, SET_MODULE} from './module.actions';
import * as programActions from './module.actions';
import {initialState, State} from "./module.state";

export function reducer(state: any, action: programActions.ModuleActions): any {
  switch (action.type) {
    case SET_MODULE: {
      return {
        ...state,
        selectedModuleKey: action.payLoad,
      } as State;
    }
    case RESET_MODULE: {
      return initialState as State;
    }
    default: {
      return {
        ...state
      }
    }
  }
}
