import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {BpmnTask} from "../../modules/fx-overlay/bpmn/domain";
import {select, Store} from "@ngrx/store";
import {AppState, getBookDate, getClient, getModule, getPortfolio, getTask} from "../../app.state";
import {combineLatest, Observable} from "rxjs";
import {formatDate} from "@angular/common";
import {first, map, pluck, switchMap, throttleTime} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class XhrInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>, @Inject(LOCALE_ID) private locale: string) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return combineLatest([
      this.store.pipe(
        select(getModule),
        pluck('selectedModuleKey'),
        map(key => key || ''),
        first()
      ),
      this.store.pipe(
        select(getClient),
        map(cv => cv.isSelected ? cv.key : ''),
        first()
      ),
      this.store.pipe(
        select(getTask),
        throttleTime(500),
        map(task => {
          const taskToExecute = task.taskToExecute as BpmnTask;
          if (taskToExecute && taskToExecute.url && taskToExecute.id) {
            return taskToExecute.id;
          }
          return '';
        }),
        first()
      ),
      this.store.pipe(
        select(getBookDate),
        pluck('date'),
        map(bookDate => bookDate ? formatDate(bookDate, 'yyyy-MM-dd', this.locale) : ''),
        first()
      ),
      this.store.pipe(
        select(getPortfolio),
        pluck('portfolioKey'),
        map(key => key || ''),
        first()
      )
    ]).pipe(switchMap(([moduleKey, clientKey, taskId, bookDate, portfolioKey]) => {
      const xhr = req.clone({
        setHeaders: {
          'X-Requested-With': 'XMLHttpRequest',
          'taskId': taskId,
          'X-Client-Key': clientKey,
          'X-Module-Key': moduleKey,
          'X-Book-Date': bookDate,
          'X-Portfolio-Key': portfolioKey,
        }
      });
      return next.handle(xhr);
    }))
  }
}
