import * as programActions from './client.actions';
import {RESET_CLIENT, SET_CLIENT} from './client.actions';
import {initialState, State} from "./client.state";

export function reducer(state: any, action: programActions.ClientActions): any {
  switch (action.type) {
    case SET_CLIENT: {
      return {
        ...state,
        ...action.payLoad,
        isSelected: true
      } as State;
    };
    case RESET_CLIENT: {
      return initialState as State;
    };
    default: {
      return {
        ...state,
      }
    }
  }
}
