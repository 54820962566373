import {Component, Inject} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Principal} from "./domain/UserPrincipal";
import {Router} from "@angular/router";
import {DOCUMENT} from "@angular/common";
import {StyleService} from "@services/style.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{

  title = 'nnip-frontend';

  activated() {
    if (localStorage.getItem('currentUser')) {
      const userPrincipal: Principal = JSON.parse(localStorage.getItem('currentUser')).principal

      if (this.router.url !== '/login' && !userPrincipal.userEntity.activatedByFirstLogon) {
        return this.router.navigateByUrl('/first-login');
      }
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public translate: TranslateService,
    private router: Router,
    private styleService: StyleService

  ) {
    translate.setDefaultLang('en');
    translate.addLangs(['en']);
    this.styleService.init(window.location.href)
  }

  styleResolver() {
    return 'milliman_'
  }

  // loadStyle(styleName: string) {
  //   const head = this.document.getElementsByTagName('head')[0];
  //
  //   let themeLink = this.document.getElementById(
  //     'client-theme'
  //   ) as HTMLLinkElement;
  //   if (themeLink) {
  //     themeLink.href = styleName;
  //   } else {
  //     const style = this.document.createElement('link');
  //     style.id = 'client-theme';
  //     style.rel = 'stylesheet';
  //     style.href = `${styleName}`;
  //
  //     head.appendChild(style);
  //   }
  // }
}
