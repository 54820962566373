import * as programActions from './user.actions';
import {INIT_USER, NEW_USER, SET_USER} from "./user.actions";
import {User} from "../../domain/User";
import {initialState} from "./user.state";


export function reducer(state: any, action: programActions.UserAction) {
  switch (action.type) {
    case SET_USER: {
      const payloadAsUser = action.payLoad['principal'].userEntity as User
      return {
        ...state,
        id: payloadAsUser.id,
        firstName: payloadAsUser.firstName,
        middleName: payloadAsUser.middleName,
        lastName: payloadAsUser.lastName,
        email: payloadAsUser.email,
        clients: [...payloadAsUser.clients],
        roles: [...payloadAsUser.roles],
        modules: [...payloadAsUser.modules]
      }
    }
    case INIT_USER: {
      const currentUser = JSON.parse(localStorage.getItem('currentUser'))
      if (currentUser) {
        const userEntity = currentUser['principal'].userEntity as User
        return {
          ...state,
          id: userEntity.id,
          firstName: userEntity.firstName,
          middleName: userEntity.middleName,
          lastName: userEntity.lastName,
          email: userEntity.email,
          clients: [...userEntity.clients],
          roles: [...userEntity.roles],
          modules: [...userEntity.modules]
        }
      } else {
        return {
          ...initialState
        }
      }
    }
    case  NEW_USER: {
      return {
        ...initialState
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
}
