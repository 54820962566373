import {RESET_BOOK_DATE, SET_BOOK_DATE} from "./bookdate.actions";
import {initialState, State} from "./bookdate.state";

export function reducer(state: any, action: any): State {
  switch (action.type) {
    case SET_BOOK_DATE: {
      return {
        ...state,
        date: action.payLoad,
      };
    };

    case RESET_BOOK_DATE: {
      return initialState as State;
    };

    default: {
      return {
        ...state
      }
    }
  }
}
