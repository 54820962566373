import {Action} from "@ngrx/store";

export const SET_MODULE = "Set Module";
export const RESET_MODULE = "Reset Module";

export class SetModule implements Action {
  readonly type: string = SET_MODULE;

  constructor(public payLoad: string) {
  }
}

export class ResetModule implements Action {
  readonly type: string = RESET_MODULE;
  constructor(public payLoad: any) {
  }
}

export type ModuleActions = SetModule | ResetModule;
