import {SET_MODULES} from './modules.actions';
import * as programActions from './modules.actions';
import {State} from "./modules.state";

export function reducer(state: any, action: programActions.ModulesAction): any {
  switch (action.type) {
    case SET_MODULES: {
      return {
        ...state,
        modules: action.payLoad,
      } as State
    }
    default: {
      return {
        ...state
      }
    }
  }
}
