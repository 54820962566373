import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";
import {IPasswordPolicy} from "@core/services/auth.service";

export class Credentials {

  constructor(public password: string, public confirmPassword: string) {

  }
}


export class PasswordPolicy {
  description: string;
  lengthMin: number;
  lengthMax: number;
  uppercaseMin: number;
  lowercaseMin: number;
  digitMin: number;
  specialMin: number;

  private constructor(
  ) {
  }

  public static fromJson(json: IPasswordPolicy): PasswordPolicy {
    const policy = new PasswordPolicy();
    policy.description = json.description;
    policy.lengthMin = json.lengthMin;
    policy.lengthMax = json.lengthMax;
    policy.uppercaseMin = json.uppercaseMin;
    policy.lowercaseMin = json.lowercaseMin;
    policy.digitMin = json.digitMin;
    policy.specialMin = json.specialMin;
    return policy;
  }
}

export function passwordPolicyValidator(policy: PasswordPolicy): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let password: string = control.value;

    if (password.indexOf(' ') > -1) {
      return {'passwordPolicy': 'noWhitespace'};
    } else if (password.length < policy.lengthMin) {
      return {'passwordPolicy': 'lengthMin'};
    } else if (password.length > policy.lengthMax) {
      return {'passwordPolicy': 'lengthMax'};
    } else if (password.replace(/[^A-Z]/g, '').length < policy.uppercaseMin) {
      return {'passwordPolicy': 'uppercaseMin'};
    } else if (password.replace(/[^a-z]/g, '').length < policy.lowercaseMin) {
      return {'passwordPolicy': 'lowercaseMin'};
    } else if (password.replace(/[^0-9]/g, '').length < policy.digitMin) {
      return {'passwordPolicy': 'digitMin'};
    } else if (password.replace(/[a-zA-Z0-9]/g, '').length < policy.specialMin) {
      return {'passwordPolicy': 'specialMin'};
    }
  }
}
