import {Action} from "@ngrx/store";

export const INIT_CLIENT = "Init Client";
export const SET_CLIENT = "Set Client";
export const RESET_CLIENT = "Reset Client";

export class InitClient implements Action {
  readonly type: string = INIT_CLIENT;
  constructor(public payLoad: any) {
  }
}

export class SetClient implements Action {
  readonly type: string = SET_CLIENT;
  constructor(public payLoad: {name: string, code: string, key: string}) {
  }
}

export class ResetClient implements Action {
  readonly type: string = RESET_CLIENT;
  constructor(public payLoad: any) {
  }
}

export type ClientActions = SetClient | ResetClient | InitClient;
