import {Action} from "@ngrx/store";

export const INIT_BOOK_DATE = "Init book date";
export const SET_BOOK_DATE = "Set book date";
export const RESET_BOOK_DATE = "Reset book date";

export class InitBookDate implements Action {
  readonly type: string = INIT_BOOK_DATE;
  constructor(public payLoad: Date) {
  }
}

export class SetBookDate implements Action {
  readonly type: string = SET_BOOK_DATE;
  constructor(public payLoad: Date) {
  }
}

export class ResetBookDate implements Action {
  readonly type: string = RESET_BOOK_DATE;
  constructor() {
  }
}

export type BookdateActions = InitBookDate | SetBookDate | ResetBookDate;
