import {Client} from "../../domain/grabber/Client";
import {RoleType} from "../../domain/Role";

export interface State {
  firstName: string;
  middleName: string;
  lastName: string;
  userName: string;
  email: string;
  clients: Client[]
  roles: RoleType[]
  modules: string[]
}

export const initialState = {} as State;
