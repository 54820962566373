import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Inject, Injectable, Injector} from '@angular/core';
import {AuthService} from './auth.service';
import {Router} from "@angular/router";
import {ToastService} from "@services/toast.service";

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    @Inject(Injector) private readonly injector: Injector,
    private authenticationService: AuthService,
    private toast: ToastService,
    private router: Router,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        // retry(1),
        catchError((errorResponse: HttpErrorResponse) => {
          if (errorResponse.status === 401) {
            // auto logout if 401 response returned from api
            this.authenticationService.logout();
            this.router.navigate(['/login']);
          }

          let errorMessage = '';
          if (errorResponse.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${errorResponse.error ? errorResponse.error.message : ''}`;
          } else {
            // server-side error
            errorMessage = `Error: ${errorResponse.error ? errorResponse.error.message : ''}`;
          }
          if (errorResponse.status !== 401 && errorResponse.status !== 403) {
            this.toast.error("Unfortunately, something went wrong");
          }
          return throwError({message: errorMessage, details: errorResponse.error});
        })
      );
  }
}
