import {Action} from "@ngrx/store";
import {BpmnTask} from "../../modules/fx-overlay/bpmn/domain";

export const SET_TASK_TO_EXECUTE = "Set Task to execute";
export const CLEAR_TASK_TO_EXECUTE = "Clear Task to execute";

export class SetTaskToExecute implements Action {
  readonly type: string = SET_TASK_TO_EXECUTE;

  constructor(public payLoad: BpmnTask) {
  }
}

export class ClearTaskToExecute implements Action {
  readonly type: string = CLEAR_TASK_TO_EXECUTE;
  constructor() {}
}


export type TaskAction = SetTaskToExecute | ClearTaskToExecute;
