import {Action} from "@ngrx/store";

export const UPDATE_STATE = "Update bpmn statee";

export class UpdateBpmnState implements Action {
  readonly type: string = UPDATE_STATE;

  constructor(public payLoad: boolean) {
  }
}



export type BpmnAction = UpdateBpmnState;
