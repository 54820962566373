import {Action} from "@ngrx/store";
import {State} from "@app/ngrx.store/workbook.state/workbook.state";
import {Workbook} from "@domain/Workbook";

export const INIT_WORKBOOK = "Init Workbook";
export const SET_WORKBOOK = "Set Workbook";
export const RESET_WORKBOOK = "Reset Workbook";

export class InitWorkbook implements Action {
  readonly type: string = INIT_WORKBOOK;
  constructor(public payLoad: any) {
  }
}

export class SetWorkbook implements Action {
  readonly type: string = SET_WORKBOOK;
  constructor(public payLoad: Workbook) {
  }
}

export class ResetWorkbook implements Action {
  readonly type: string = RESET_WORKBOOK;
  constructor() {
  }
}

export type WorkbookActions = SetWorkbook | ResetWorkbook | InitWorkbook;
