import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {catchError, first, map} from 'rxjs/operators';
import {Credentials, PasswordPolicy} from '../../domain/Credentials';
import {Role, RoleType} from '../../domain/Role';
import {Store} from '@ngrx/store';
import {AppState} from "../../app.state";
import {SetUser} from "../../ngrx.store/user.state";
import {User} from "../../domain/User";
import {FormBuilder} from "@angular/forms";
import {
  API_AUTHENTICATION,
  API_AUTHENTICATION_FIRST_LOGIN,
  API_AUTHENTICATION_PASSWORD_POLICY,
  API_AUTHENTICATION_RESET_EMAIL,
  API_AUTHENTICATION_RESET_PASSWORD,
  API_LOGOUT
} from "../../shared/constants/api-keys.constant";
import {LocalStorageService} from "./local-storage.service";
import {storageKeys} from "../../shared/constants/storage-keys.enum";

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private readonly loggedIn = new BehaviorSubject<boolean>(false);

  private readonly roleDefinitions = [
    new Role(0, RoleType.ADMIN, 'users'),
    new Role(1, RoleType.DATA_ADMIN, 'wf-data-source'),
    new Role(2, RoleType.DATA_MANAGER, 'modules'),
    new Role(3, RoleType.REPORTER, 'wf-report-instance'),
    new Role(4, RoleType.REPORT_ADMIN, 'wf-report-template'),
    new Role(5, RoleType.MODULE_ADMIN, 'dashboard')
  ];

  private subs = new Subscription();

  constructor(
    @Inject('window') private window: Window,
    private http: HttpClient,
    private router: Router,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private storage: LocalStorageService,
  ) {
  }

  get currentUser() {
    return this.storage.get(storageKeys.currentUser);
  }

  get hasCurrentUser(): boolean {
    return this.storage.has(storageKeys.currentUser);
  }

  get hasName(): boolean {
    return this.storage.has(storageKeys.name);
  }

  get roles() {
    if (this.hasCurrentUser) {
      return this.currentUser['authorities'].map(authority => authority.authority)
    }

    return []
  }

  getDefaultRouteForUser() {
    if (this.roles.length === 0) {
      return 'login'
    }
    const firstRole: string = this.roles[0]

    return this.roleDefinitions.find(role => role.roleType.toString() === firstRole).defaultRoute
  }

  sendResetEmail(emailAddress: string): Observable<Object> {
    return this.http.get(API_AUTHENTICATION_RESET_EMAIL + '?emailAddress=' + emailAddress).pipe(first())
  }

  isAuthenticated(): boolean {
    if (this.hasName) {
      this.loggedIn.next(true);
    }
    return this.loggedIn.getValue();
  }

  isAdvisor() {
    return this.hasPermission(RoleType.DATA_MANAGER);
  }

  isDataManager() {
    return this.roles.includes(RoleType.DATA_MANAGER)
  }

  isClient() {
    return this.hasPermission(RoleType.REPORTER);
  }

  public hasPermission(roleGroup: RoleType): boolean {
    return this.roles.indexOf(roleGroup) > -1;
  }

  logout(): void {
    const currentUser = this.storage.get(storageKeys.currentUser);
    this.subscribeOnLogOut(currentUser);
    this.zeroingUser();
    this.router.navigate(['/login']);
  }

  private zeroingUser() {
    this.loggedIn.next(false);
    this.storage.remove(storageKeys.currentUser);
    this.storage.remove(storageKeys.name);
  }

  firstTimeChangePassword(credentials: Credentials): Observable<any> {
    return this.http.post(API_AUTHENTICATION_FIRST_LOGIN, credentials)
  }

  resetChangePassword(credentials: Credentials, token: String): Observable<any> {
    return this.http.post(API_AUTHENTICATION_RESET_PASSWORD + '?token=' + token, credentials)
  }

  getPasswordPolicy(): Observable<PasswordPolicy> {
    return this.http.get<IPasswordPolicy>(API_AUTHENTICATION_PASSWORD_POLICY).pipe(map(policy =>
      PasswordPolicy.fromJson(policy)
    ));
  }

  authenticate(credentials: ICredentials): Observable<IAuthMessage> {
    const headers = new HttpHeaders(credentials ? {
      authorization: 'Basic ' + this.window.btoa(credentials.username + ':' + credentials.password)
    } : {});

    return this.authenticateRequest(headers).pipe(
      catchError((error) => of(error)),
      map(response => {
        if (!!response && response['name']) {
          this.setUser(response);
          this.loggedIn.next(true);
          return {success: true};
        } else if (!!response && response['details']) {
          this.loggedIn.next(false);
          return {success: false, details: response['details']};
        } else {
          this.loggedIn.next(false);
          return {success: false};
        }
      })
    )
  }

  private setUser(user) {
    this.storage.set(storageKeys.currentUser, user);
    this.storage.set(storageKeys.name, user['name']);
    this.store.dispatch(new SetUser(user as User))
  }

  private authenticateRequest(headers: HttpHeaders) {
    return this.http.get(API_AUTHENTICATION, {headers});
  }

  /**
   * Subscription manager:
   * (As this method can call more then 1 time, we need to manage the subscription;)
   * @param currentUser: string;
   */
  private subscribeOnLogOut(currentUser: {}): void {
    if (!this.subs.closed) {
      /**
       * Removing of previous subscribers;
       */
      this.subs.unsubscribe();
      this.subs = new Subscription();
    }
    /**
     * New subscriber;
     */
    this.subs.add(
      this.http.post<any>(API_LOGOUT, currentUser).pipe(first()).subscribe()
    );
  }
}

export interface ICredentials {
  username: string;
  password: string;
}

export interface IAuthMessage {
  success: boolean;
  details?: any;
}

export interface IPasswordPolicy {
  description: string;
  lengthMin: number;
  lengthMax: number;
  uppercaseMin: number;
  lowercaseMin: number;
  digitMin: number;
  specialMin: number;
}

