import {AbstractControl, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Injectable} from "@angular/core";
import {DataFormatField} from "@domain/grabber/DataFormatField";
import {idFieldName} from "@shared/constants";
import {MlModule} from "@domain/Module";

export function filterItemsByKey<T>(keys: string[], items: T[]): T[] {
  return keys.reduce((acc, key) => {
    acc.push(items.find(cv => cv['key'] === key));
    return acc;
  }, [] as T[])
}

export function mlTrackById(index, cv): unknown {
  return !!cv.id ? cv.id : index;
}

export abstract class FormGroupTypeSafe<T> extends FormGroup {
  // give the value a custom type s
  value: T;

  // create helper methods to achieve this syntax eg: this.form.getSafe(x => x.heroName).patchValue('Himan')
  public abstract getSafe(propertyFunction: (typeVal: T) => any): AbstractControl;

  public abstract setControlSafe(propertyFunction: (typeVal: T) => any, control: AbstractControl): void;

  // If you need more function implement declare them here but implement them on FormBuilderTypeSafe.group instantiation.
  public abstract patchValue(value: Partial<T>, options?: {}): void;
}

export class FormControlTypeSafe<T> extends FormControl {
  value: T;
}

export type FormGroupControlsOf<T> = {
  [P in keyof T]: FormControl | FormGroup;
};

@Injectable()
export class FormBuilderTypeSafe extends FormBuilder {
  // override group to be type safe
  group<T>(
    controlsConfig:
      | FormGroupControlsOf<T>
      | {
      [key in keyof T]: any;
    },
    extra?: {
      [key: string]: any;
    } | null
  ): FormGroupTypeSafe<T> {
    /*NOTE the return FormGroupTypeSafe<T> */

    // instantiate group from angular type
    const gr = super.group(controlsConfig, extra) as FormGroupTypeSafe<T>;

    const getPropertyName = (propertyFunction: (typeVal: T) => any): string => {
      // https://github.com/dsherret/ts-nameof - helped me with the code below, THANX!!!!
      // propertyFunction.toString() sample value:
      //  function(x) { return x.hero.address.postcode;}
      // we need the 'hero.address.postcode'
      // for gr.get('hero.address.postcode') function
      const properties = propertyFunction
        .toString()
        .match(/(?![. ])([a-z0-9_]+)(?=[};.])/gi)
        .splice(1);

      const r = properties.join('.');
      return r;
    };

    if (gr) {
      // implement getSafe method
      gr.getSafe = (propertyFunction: (typeVal: T) => any): AbstractControl => {
        const getStr = getPropertyName(propertyFunction);
        const p = gr.get(getStr) as FormGroupTypeSafe<T>;
        return p;
      };

      // implement setControlSafe
      gr.setControlSafe = (propertyFunction: (typeVal: T) => any, control: AbstractControl): void => {
        const getStr = getPropertyName(propertyFunction);
        gr.setControl(getStr, control);
      };

      // implement more functions as needed
    }
    return gr;
  }
}

export function getNextIdValue(items: { id: number}[] ): number {
  let newId: number = items && items.length || 1;
  if (!!items) {
    while (ifAlreadyExist(items, newId)) {
      newId++;
    }
  }

  return newId;
}

function ifAlreadyExist(items, id): boolean {
  return items && items.some(cv => cv.id === id);
}

export const concatUrl = (...strArr: string[]): string => strArr.join('/');

export function getLinkExpressionKey(linkExpression: string): string {
  const regExp1 = /[^{]*$/; // select text after '{';
  linkExpression = linkExpression.match(regExp1)[0];
  const regExp2 = /([^}]+)/; // select text before '}';
  return linkExpression.match(regExp2)[0];
}

export function updateLinkExpression(linkExpression: string, template): string {
  const regExp = /\{(.+?)\}/;  // select text '{...}';
  return linkExpression.replace(regExp, template);
}

export function getFieldNameExpression(customExpression: string): string {
  const regExp = /\((.+?)\)/;  // remove text '(...)';
  return customExpression.replace(regExp, '');
}

export function getFormatExpression(customExpression: string): string {
  if (customExpression.indexOf('(') < 0) {
    return;
  }
  const regExp1 = /[^(]*$/; // select text after '(';
  customExpression = customExpression.match(regExp1)[0];
  const regExp2 = /([^)]+)/; // select text before ')';
  return customExpression.match(regExp2)[0];
}

export function getCustomFieldsSet(allFields: DataFormatField[], customFields: string[]): DataFormatField[] {
  const customFieldSet = customFields.reduce((acc, fieldName) => {
    fieldName = fieldName.trim().toUpperCase();
    const dataFormatField = allFields.find(cv => cv.name.trim().toUpperCase() === fieldName);
    if (!!dataFormatField) {
      acc.push(dataFormatField);
    }
    return acc;
  }, []);
  return customFieldSet.length > 0 ? customFieldSet : allFields;
}

export function extractKeyFieldValue(object: {_id: any; [key: string]: any}): string {
  if (!object) {
    return '';
  }
  const fields = Object.keys(object);
  fields.splice(fields.indexOf(idFieldName), 1);
  return fields[0] || '';
}

export function extractKeys(object: {_id: any; [key: string]: any}): string[] {
  if (!object) {
    return null;
  }
  const keys = Object.keys(object);
  keys.splice(keys.indexOf(idFieldName), 1);
  return keys || null;
}

export function filterModulesByKeys(keysArr: string[], modules: MlModule[]) {
  return keysArr.reduce((acc, item) => {
    const module = modules.find(cv => cv.key === item)
    if (!!module) {
      acc.push(module);
    }
    return acc;
  }, [])
}

export function getElementById(data: any[], key: string): any {
  return data.find(v => v[idFieldName] === key)
}

export function normalizeIconName(iconName: string): string {
  /**
   * Expected icon name is 'iconName#fffff';
   * Removes ending (color parameter) '#ffffff'
   */
  const sharpSymbolIndex = iconName.indexOf('#');
  /**
   * returns only first part before '#': 'iconName'
   */
  return sharpSymbolIndex < 0 ? iconName : iconName.substring(0, sharpSymbolIndex);
}

export function clearHtmlContainer(container: HTMLElement[]): void {
  if (container && container.length > 0) {
    for (const el of container) {
      el.remove();
    }
  }
}

