export interface State {
  name: string;
  code: string;
  key: string;
  isSelected: boolean
}

export const initialState = {
  isSelected: false
} as State;
