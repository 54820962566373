import {Inject, Injectable, InjectionToken} from '@angular/core';
import {StorageDecoder, StorageEncoder, StorageService, StorageService as NGXStorage} from "ngx-webstorage-service";

export const ML_LOCAL_STORAGE = new InjectionToken<StorageService>('ML_LOCAL_STORAGE');

@Injectable()
export class LocalStorageService {
  constructor(@Inject(ML_LOCAL_STORAGE) private storage: NGXStorage) {}
  /**
   * Checks whether an entry with the specified key exists in the storage.
   */
  has(key: string): boolean {
    return this.storage.has(key);
  }
  /**
   * Retrieves the value stored for the entry that is associated with the specified key. If no such entry exists or if the service for
   * some reason is unable to fetch the value of the entry then `undefined` will be returned.
   */
  get<T>(key: string): T | undefined;
  /**
   * Retrieves the value stored for the entry that is associated with the specified key. The given decoder is used to convert the stored
   * value to the desired type. If no entry for the specified key exists or if the decoder is unable to decode the stored value, then
   * `undefined` will be returned.
   */
  get<X>(key: string, decoder?: StorageDecoder<X>): X | undefined {
    return this.storage.get(key, decoder);
  }
  /**
   * Creates or updates the entry identified by the specified key with the given value. Storing a value into the storage service will
   * ensure that an equivalent of the value can be read back, i.e. the data and structure of the value will be the same. It, however, does
   * not necessarily return the same reference.
   */
  set<T>(key: string, value: T): void;
  /**
   * Creates or updates the entry identified by the specified key with the given value. The specified encoder is used to convert the given
   * value into a format that can be stored by the storage service's underlying storage.
   *
   * Storing a value into the storage service will ensure that an equivalent of the value can be read back, i.e. the data and structure of
   * the value will be the same. It, however, does not necessarily return the same reference.
   */
  set<T>(key: string, value: T, encoder?: StorageEncoder<T>): void {
    this.storage.set(key, value, encoder);
  }
  /**
   * Removes the entry that is identified by the specified key. Attempting to remove an entry for an unknown key will have no effect.
   * Attempting to retrieve an entry via the `get` method after it has been removed will result in `undefined`.
   */
  remove(key: string): void {
    this.storage.remove(key);
  }
  /**
   * Clears the storage by removing all entries. Subsequent `get(x)` calls for a key *x* will return `undefined`, until a new value is set
   * for key *x*.
   */
  clear(): void {
    this.storage.clear();
  }
  /**
   * Creates a new storage service that uses the specified transcoder by default for read and write operations. The new storage service
   * uses the storage service on which this function is invoked as underlying storage. Both storage services will thus be able to access
   * the same data.
   *
   * The default transcoder will not be changed for the storage service on which this function is invoked.
   */
  // withDefaultTranscoder<X>(transcoder: StorageTranscoder<X>): StorageService<X>;
}
